import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import Input from '../components/input/Input';
import Button from '../components/Button';
import { UserContext } from '../context/UserContext';

const StyledRegister = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const StyledRegisterHeader = styled.h2`
    color: #ffffff;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    padding-top: 20px;
`;

const StyledRegisterForm = styled.form`
    display: block;
    width: 300px;
`;

const Register = () => {
    const userContext = useContext(UserContext);
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        const fields = Array.prototype.slice
            .call(e.target)
            .filter((el) => el.name)
            .reduce(
                (form, el) => ({
                    ...form,
                    [el.name]: el.value,
                }),
                {},
            );

        userContext.handleRegister(fields).catch((err) => {
            setLoading(false);
            alert(err.message || 'Invalid registration details');
        });
    };

    return (
        <>
            {/* <Helmet /> */}
            <StyledRegister>
                <StyledRegisterHeader>REGISTER</StyledRegisterHeader>

                <StyledRegisterForm onSubmit={handleSubmit}>
                    <Input name="firstName" label="First name" placeholder="John" required />
                    <Input
                        type="tel"
                        name="phoneNumber"
                        label="Phone number"
                        placeholder="090xxxxxxxx"
                        minLength={11}
                        maxLength={11}
                        pattern="0[7-9][0-1]{1}[0-9]{8}"
                        required
                    />

                    <Button text="Continue" loading={loading} />
                </StyledRegisterForm>
            </StyledRegister>
        </>
    );
};

export default Register;
