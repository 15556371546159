const ROUTE = {
    HOME: '/',
    PLAY: '/',
    PRIVACY_POLICY: '',
    ADMIN: '/admin',
};

const ROUTES: { to: string; exact?: boolean; scroll?: any; text: string }[] = [
    { to: ROUTE.HOME, exact: true, text: 'Home' },
    { to: ROUTE.ADMIN, exact: true, text: 'Admin' },
];

export { ROUTE, ROUTES };
