const getItem = (key) => {
    const value = localStorage.getItem(key) || null;

    try {
        if (value) {
            return JSON.parse(value);
        } else {
            return value;
        }
    } catch (e) {
        return value;
    }
};

const setItem = (key, value) => {
    let valueString = '';

    try {
        valueString = JSON.stringify(value);
    } catch (e) {
        valueString = value;
    }

    localStorage.setItem(key, valueString);
};

const removeItem = (key) => {
    localStorage.removeItem(key);
};

const clear = () => {
    localStorage.clear();
};

export default {
    getItem,
    setItem,
    removeItem,
    clear,
};
