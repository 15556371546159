import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import ReactModal from 'react-modal';
import Register from './Register';
import { UserContext } from '../context/UserContext';
import Admin from './Admin';

const StyledHome = styled.main`
    background: url('/images/default_menu_background.png') 0 0 no-repeat;
    background-size: 100% 100%;
    position: absolute;
    width: 100%;
    max-width: 400px;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
`;

ReactModal.setAppElement('#root');

const Home = () => {
    const userContext = useContext(UserContext);
    const isSignedIn = userContext.isSignedIn;

    useEffect(() => {
        if (isSignedIn) {
            const w: any = window;
            w.LEvent.addEventListener(document, w.LMouseEvent.TOUCH_END, w.LGlobal.ll_touchEnd);
        }
    }, [isSignedIn]);

    return (
        <>
            <div id="mylegend" style={{ display: isSignedIn ? 'block' : 'none' }}></div>
            {!isSignedIn && (
                <StyledHome>
                    <Register />
                </StyledHome>
            )}
        </>
    );
};

export default Home;
