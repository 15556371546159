import React from 'react';
import { ThemeProvider } from 'styled-components';
import ReactModal from 'react-modal';
import { UserProvider } from './context/UserContext';
import theme from './utilities/theme';
import Home from './pages/Home';
import { PublicRoute, Router, Switch } from './utilities/router/Router';
import { ROUTE } from './constants/routes';
import Admin from './pages/Admin';

ReactModal.setAppElement('#root');

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Switch>
                    <PublicRoute exact path={ROUTE.ADMIN} component={Admin} />
                    <UserProvider>
                        <Home />
                    </UserProvider>
                </Switch>
            </Router>
        </ThemeProvider>
    );
};

export default App;
