import React, { createContext } from 'react';
import {
    isMobile,
    isBrowser,
    isTablet,
    isSmartTV,
    isMobileSafari,
    isChromium,
    isAndroid,
    isWinPhone,
    isIOS,
    isChrome,
    isFirefox,
    isSafari,
    isOpera,
    isIE,
    isEdge,
    isYandex,
    osVersion,
    osName,
    fullBrowserVersion,
    browserVersion,
    browserName,
    getUA,
    deviceType,
    isIOS13,
    isIPad13,
    isIPhone13,
    isIPod13,
    isElectron,
    isEdgeChromium,
    isLegacyEdge,
    isWindows,
    isMacOs,
} from 'react-device-detect';
import { API_URL, STORAGE_KEYS } from '../constants/config';
import { useColdFetch, useLocalStorage } from '../utilities/hooks';

export const UserContext: React.Context<any> = createContext(null);

export const UserProvider = (props) => {
    const [user, setUser] = useLocalStorage(STORAGE_KEYS.USER);
    const [, register]: any = useColdFetch({});

    const handleLogout = () => {
        setUser(null);
    };

    const handleRegister = async ({ firstName, phoneNumber }) => {
        const body = {
            firstName,
            phoneNumber,
            userAgent: getUA || '',
            isMobile,
            isBrowser,
            isTablet,
            isSmartTV,
            isMobileSafari,
            isChromium,
            isAndroid,
            isWinPhone,
            isIOS,
            isChrome,
            isFirefox,
            isSafari,
            isOpera,
            isIE,
            isEdge,
            isYandex,
            osVersion,
            osName,
            fullBrowserVersion,
            browserVersion,
            browserName,
            deviceType,
            isIOS13,
            isIPad13,
            isIPhone13,
            isIPod13,
            isElectron,
            isEdgeChromium,
            isLegacyEdge,
            isWindows,
            isMacOs,
        };

        return register({
            url: API_URL.REGISTER,
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body,
        }).then(({ data }) => {
            if (data) {
                setUser({ ...data });
            } else {
                throw new Error('Empty data returned');
            }
        });
    };

    return (
        <UserContext.Provider
            value={{
                isSignedIn: !!(user && user.phoneNumber),
                user,
                handleRegister,
                handleLogout,
            }}
        >
            {props.children}
        </UserContext.Provider>
    );
};
