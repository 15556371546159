import { useState, useReducer, useEffect, useRef } from 'react';
import { useAsyncTask } from 'react-hooks-async';
import storage from './storage';

function fetchReducer(state, { type, result, error }: any) {
    switch (type) {
        case 'fetching': {
            return { error: null, result: null, pending: true, started: true };
        }
        case 'success': {
            return { error: null, result, pending: false, started: false };
        }
        case 'error': {
            return { error, result: null, pending: false, started: false };
        }
        case 'reset': {
            return { error: null, result: null, pending: null, started: false };
        }
        default:
            throw new Error(`Unsupported type: ${type}`);
    }
}

function useHotFetch({ url, method = 'post', headers = {}, data }) {
    const [state, dispatch] = useReducer(fetchReducer, {
        error: null,
        result: null,
        pending: null,
        started: false,
    });

    let dataString;

    if (data) {
        dataString = !!data && JSON.stringify(data);
    } else {
        dataString = null;
    }

    useEffect(() => {
        if (url) {
            dispatch({ type: 'fetching' });

            fetch(url, {
                method,
                body: dataString,
                headers,
            })
                .then(async (r) => {
                    const status = `${r.status}`;
                    r = r && (await r.json());

                    const { error, errors, message = 'Something went wrong' }: any = r;

                    if ((status && !status.startsWith('2')) || (errors && errors.length > 0)) {
                        // Throw error here
                    }

                    return r;
                })
                .then((res) => {
                    return dispatch({ type: 'success', result: res });
                })
                .catch((e) => {
                    dispatch({ type: 'error', error: e && (e.error || e.errors) });
                });
        }

        return () => {
            dispatch({ type: 'reset' });
        };
    }, [url, dataString]);

    return state;
}

const fetcher = async ({ signal }, { url, method, body, headers }) => {
    return await fetch(url, {
        method,
        body: body ? JSON.stringify(body) : null,
        signal,
        headers,
    }).then(async (r) => {
        const status = `${r.status}`;
        r = r && (await r.json());

        const { error, errors, message = 'Something went wrong' }: any = r;

        if ((status && !status.startsWith('2')) || (errors && errors.length > 0)) {
            // Throw error here
        }

        return r;
    });
};

function useColdFetch({ url, method = 'post', headers = {}, body }: any) {
    const { start: asyncStart, started, pending, error, result } = useAsyncTask(fetcher);

    return [
        {
            started,
            pending,
            error,
            result,
        },
        (data: any = {}) => {
            return asyncStart({
                url,
                method,
                headers,
                body: data.body || body,
                ...data,
            });
        },
    ];
}

function useLocalStorage(key) {
    const [value, setValue] = useState(storage.getItem(key));

    useEffect(() => {
        if (value !== undefined) {
            storage.setItem(key, value);
        }
    }, [key, value]);

    return [value, setValue];
}

export { useHotFetch, useColdFetch, useLocalStorage };
