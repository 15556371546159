export const seo = {
    siteTitle: 'Bigi Racing Game',
    description: 'Bigi Racing Game',
    canonicalUrl: '',
    image: '/logo.png',
    keywords: [],
    author: '',
};

export const social = { fbAppID: '', twitter: 'bigi', instagram: '' };

export const STORAGE_KEYS = { USER: btoa('bigi-user') };

export const API_URL = {
    REGISTER: 'https://bigi-racing-backend.terragonbase.com/user',
    LOGIN: 'https://',
    SAVE_SCORE: 'https://bigi-racing-backend.terragonbase.com/user-data',
    LEADERBOARD: 'https://bigi-racing-backend.terragonbase.com/leaderboard',
    REPORT_DATA: 'https://bigi-racing-backend.terragonbase.com/unique',
    REWARDED_USERS: 'https://bigi-racing-backend.terragonbase.com/rewarded',
};
