import Typography from 'typography';
import funstonTheme from 'typography-theme-funston';
import { injectFonts } from './helper';

funstonTheme.overrideThemeStyles = () => ({
    a: {
        color: 'inherit',
        cursor: 'pointer',
        textDecoration: 'none',
    },
    'a:hover': {
        color: '#0ca04b',
        textDecoration: 'none',
    },
    'h2, h3, h4': {
        lineHeight: 1.4,
    },
});

const typography = new Typography({
    ...funstonTheme,
    bodyFontFamily: ['Poppins', '-apple-system', 'BlinkMacSystemFont', 'Roboto', 'Oxygen'],
    headerFontFamily: ['Poppins', '-apple-system', 'BlinkMacSystemFont', 'Roboto', 'Oxygen'],
    googleFonts: undefined,
});

// Hot reload typography in development.
typography.injectStyles();
injectFonts(typography);

export default typography;
export const { rhythm, scale, options } = typography;
