import React from 'react';
import { Route } from 'react-router-dom';
import { HashRouter as Router, Switch, Redirect } from 'react-router-dom';

const PublicRoute = ({
    component: Component,
    ...rest
}: {
    component: any;
    path: string;
    exact: boolean;
}): any => {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export { Router, Switch, Redirect, PublicRoute };
