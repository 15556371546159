import React from 'react';
import styled from 'styled-components';
import { rhythm } from '../utilities/typography';

const StyledButton = styled.button`
    align-items: center;
    background: ${({ theme }) => theme.colors.white.base};
    border: 2px solid ${({ theme }) => theme.colors.secondary.base};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.secondary.base};
    cursor: pointer;
    display: flex;
    font-size: 20px;
    font-weight: 600;
    justify-content: center;
    height: 52px;
    margin: ${rhythm(1)} auto;
    max-width: 250px;
    min-width: 100px;
    padding: ${rhythm(0.5)};
    position: relative;
    transition: all 300ms;
    vertical-align: middle;
    width: 100%;
    z-index: 1;

    &:hover {
        color: ${({ theme }) => theme.colors.white.base};
        background: ${({ theme }) => theme.colors.secondary.base};
    }

    .loader {
        width: 24px;
        height: 24px;
        display: inline-block;
        padding: 0px;
        border-radius: 100%;
        border: 3px solid;
        border-top-color: ${({ theme }) => theme.colors.secondary.base};
        border-bottom-color: rgba(255, 255, 255, 0.3);
        border-left-color: ${({ theme }) => theme.colors.secondary.base};
        border-right-color: rgba(255, 255, 255, 0.3);
        -webkit-animation: loader 1s ease-in-out infinite;
        animation: loader 1s ease-in-out infinite;
    }
    @keyframes loader {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @-webkit-keyframes loader {
        from {
            -webkit-transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
        }
    }
`;

const Button = ({ text, type = 'outline', size = 'medium', loading, ...rest }: any) => {
    return (
        <StyledButton data-attr={text} className={`${type} ${size}`} {...rest}>
            {!loading && <span> {text || ''}</span>}
            {loading && <span className="loader"></span>}
        </StyledButton>
    );
};

export default Button;
