import React, { useState, useEffect } from 'react';
import { API_URL } from '../constants/config';
import logo from '../assets/logo.png';
import { CSVLink } from 'react-csv';
import Pagination from 'react-js-pagination';

const Admin = () => {
    const [reportDetails, setReportDetails] = useState([]);
    const [reportQuery, setReportQuery] = useState({
        start_date: new Date(),
        end_date: new Date(),
    });
    const [rewardedUsers, setRewardedUsers] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [activePageReward, setActivePageReward] = useState(1);
    const [itemsCountPerPage, setitemsCountPerPage] = useState(10);
    const [itemsCountPerPageReward, setitemsCountPerPageReward] = useState(10);
    let csvData = [];
    let csvDataReward = [];
    let newArr: any = [];
    let newArrRewarded: any = [];
    let loading = false;
    const [rewarded, setRewarded] = useState(false);
    let today: any = new Date();
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    today = yyyy + '-' + mm + '-' + dd;

    const checkLoad = (id) => {
        if (loading) {
            (document.getElementById(id) as HTMLInputElement).className += ' d-block';
        } else if (!loading) {
            (document.getElementById(id) as HTMLInputElement).classList.remove('d-block');
        }
    };
    const transformToCSV = (results) => {
        const allowedHeadersMap = {
            phoneNumber: 'Phone Number',
            highScore: 'Highest Score',
        };

        const allowedHeaders = Object.keys(allowedHeadersMap);

        const headers = Object.values(allowedHeadersMap);

        const data = results.map((res, i) => {
            return allowedHeaders.map((header) => {
                return res[header] || '';
            });
        });

        data.unshift(headers);
        console.log(data);
        return data;
    };
    const transformToCSVReward = (results) => {
        const allowedHeadersMap = {
            date: 'Date',
            phoneNumber: 'Phone Number',
            score: 'Score',
            rewarded: 'Amount Rewarded',
        };

        const allowedHeaders = Object.keys(allowedHeadersMap);

        const headers = Object.values(allowedHeadersMap);

        const data = results.map((res, i) => {
            return allowedHeaders.map((header) => {
                return res[header] || '';
            });
        });

        data.unshift(headers);
        console.log(data);
        return data;
    };
    const getData = () => {
        loading = true;
        checkLoad('cover-spin');
        reportQuery.start_date.setHours(1, 0, 0, 0);
        reportQuery.end_date.setHours(24, 59, 59, 59);
        let queryString = `?start_date=${reportQuery.start_date.toISOString()}&end_date=${reportQuery.end_date.toISOString()}`;
        fetch(API_URL.REPORT_DATA + queryString).then((res) => {
            res.json().then((data) => {
                loading = false;
                checkLoad('cover-spin');
                let arr = data.data.map((res) => {
                    return {
                        phoneNumber: res._id,
                        highScore: res.highest_score,
                    };
                });
                setReportDetails(arr);
            });
        });
    };
    const getRewardedUsers = () => {
        loading = true;
        checkLoad('cover-spin');
        fetch(API_URL.REWARDED_USERS).then((res) => {
            res.json().then((data) => {
                loading = false;
                checkLoad('cover-spin');
                let arr = data.data.map((res) => {
                    loading = false;
                    checkLoad('cover-spin');
                    const {
                        createdAt,
                        phoneNumber,
                        score,
                        rewarded_number,
                        reward_amount,
                        amount_rewarded,
                    } = res;
                    return {
                        date: createdAt.split('T')[0],
                        phoneNumber: phoneNumber || rewarded_number || 'Not Available',
                        score: score || 0,
                        rewarded: reward_amount || amount_rewarded || 'Not Available',
                    };
                });
                setRewardedUsers(arr);
            });
        });
    };
    useEffect(() => {
        getData();
        getRewardedUsers();
    }, []);
    const handleSubmit = (e) => {
        e.preventDefault();
        getData();
    };
    const handleChange = (e) => {
        let newDate = new Date(e.target.value);
        console.log(newDate);
        setReportQuery({ ...reportQuery, [e.target.name]: newDate });
    };
    const handlePageChange = (pageNumber) => {
        !rewarded ? setActivePage(pageNumber) : setActivePageReward(pageNumber);
    };
    const handleClick = (e) => {
        e.preventDefault();
        setRewarded((rewarded) => !rewarded);
    };
    if (!loading && reportDetails) {
        const indexOfLastPost = activePage * itemsCountPerPage;
        const indexOfFirstPost = indexOfLastPost - itemsCountPerPage;
        const currentPosts = reportDetails.slice(indexOfFirstPost, indexOfLastPost);
        csvData = transformToCSV(reportDetails);
        console.log(currentPosts);
        newArr = currentPosts.map((res: object, index) => {
            return {
                serialNumber: index + indexOfFirstPost + 1,
                ...res,
            };
        });
    }
    if (!loading && rewardedUsers) {
        const indexOfLastPost = activePageReward * itemsCountPerPageReward;
        const indexOfFirstPost = indexOfLastPost - itemsCountPerPageReward;
        const currentPosts = rewardedUsers.slice(indexOfFirstPost, indexOfLastPost);
        csvDataReward = transformToCSVReward(rewardedUsers);
        console.log(currentPosts);
        newArrRewarded = currentPosts.map((res: object, index) => {
            return {
                serialNumber: index + indexOfFirstPost + 1,
                ...res,
            };
        });
    }
    return (
        <div>
            <div id="cover-spin" className="d-none"></div>
            <nav className="navbar navbar-light bg-light py-0">
                {!rewarded ? 'Report' : 'Rewarded Users'}
                <a className="navbar-brand" href="#">
                    <img src={logo} width="50" height="50" alt="logo" className="m-0" />
                </a>
            </nav>
            <div className="container mt-3 p-0">
                <button
                    className="btn btn-primary btn-sm"
                    onClick={handleClick}
                    style={{ backgroundColor: '#4941e3', borderColor: '#4941e3' }}
                >
                    {!rewarded ? 'View Rewarded Users' : 'View Report'}
                </button>
            </div>
            {rewarded && (
                <div className="container p-5 mt-2" style={{ backgroundColor: '#f8f9fa' }}>
                    <i
                        className="fas fa-redo-alt"
                        title="Reload Table"
                        style={{ cursor: 'pointer' }}
                        onClick={getRewardedUsers}
                    ></i>
                    {newArrRewarded !== null && !loading && (
                        <>
                            <div className="details d-flex justify-content-between align-items-center flex-wrap float-right">
                                <div className="d-flex flex-nowrap">
                                    <CSVLink
                                        data={csvDataReward}
                                        filename={`Bigi Lagos Hustle Report - ${today}.csv`}
                                        className="btn btn-link"
                                        title="Download Excel file"
                                        style={{
                                            color: '#0ca04b',
                                        }}
                                    >
                                        <span className="mr-3">Download Excel</span>
                                        <i
                                            className="fas fa-file-excel"
                                            style={{ fontSize: '20px', color: '#0ca04b' }}
                                        ></i>
                                    </CSVLink>
                                </div>
                            </div>

                            <table className="table mt-5" style={{ fontSize: '.875rem' }}>
                                <thead>
                                    <tr>
                                        <th scope="col">S/N</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Phone Number</th>
                                        <th scope="col">Score</th>
                                        <th scope="col">Amount Rewarded</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {newArrRewarded !== null &&
                                        newArrRewarded.map((d) => (
                                            <tr>
                                                <td>{d.serialNumber}</td>
                                                <td>{d.date}</td>
                                                <td>{d.phoneNumber}</td>
                                                <td>{d.score} points</td>
                                                <td>₦{d.rewarded}</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                            <div>
                                <Pagination
                                    hideDisabled
                                    activePage={activePageReward}
                                    itemsCountPerPage={itemsCountPerPageReward}
                                    totalItemsCount={rewardedUsers && rewardedUsers.length}
                                    pageRangeDisplayed={5}
                                    onChange={handlePageChange}
                                />
                            </div>
                        </>
                    )}
                </div>
            )}
            {!rewarded && (
                <div className="container p-5 mt-2" style={{ backgroundColor: '#f8f9fa' }}>
                    <form
                        onSubmit={handleSubmit}
                        className="form-inline p-4 rounded"
                        style={{ backgroundColor: '#fff' }}
                    >
                        <div className="form-group mr-3">
                            <label htmlFor="start_date" style={{ color: '#000' }} className="mr-2">
                                From:
                            </label>
                            <input
                                type="date"
                                name="start_date"
                                id="start_date"
                                className="form-control"
                                value={reportQuery.start_date.toISOString().substr(0, 10)}
                                onChange={handleChange}
                                max={today}
                            />
                        </div>
                        <div className="form-group mr-3">
                            <label htmlFor="end_date" style={{ color: '#000' }} className="mr-2">
                                To:
                            </label>
                            <input
                                type="date"
                                name="end_date"
                                id="end_date"
                                className="form-control"
                                value={reportQuery.end_date.toISOString().substr(0, 10)}
                                onChange={handleChange}
                                min={reportQuery.start_date.toISOString().substr(0, 10)}
                                max={today}
                            />
                        </div>
                        <button type="submit" className="btn btn-light btn-sm">
                            Get Results
                        </button>
                    </form>
                    {newArr !== null && !loading && (
                        <>
                            <div className="details d-flex justify-content-between align-items-center flex-wrap float-right">
                                <div className="d-flex flex-nowrap">
                                    <CSVLink
                                        data={csvData}
                                        filename={`Bigi Lagos Hustle Report - ${today}.csv`}
                                        className="btn btn-link"
                                        title="Download Excel file"
                                        style={{
                                            color: '#0ca04b',
                                        }}
                                    >
                                        <span className="mr-3">Download Excel</span>
                                        <i
                                            className="fas fa-file-excel"
                                            style={{ fontSize: '20px', color: '#0ca04b' }}
                                        ></i>
                                    </CSVLink>
                                </div>
                            </div>

                            <table className="table mt-5" style={{ fontSize: '.875rem' }}>
                                <thead>
                                    <tr>
                                        <th scope="col">S/N</th>
                                        <th scope="col">Phone Number</th>
                                        <th scope="col">Highest Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {newArr !== null &&
                                        newArr.map((d) => (
                                            <tr>
                                                <td>{d.serialNumber}</td>
                                                <td>{d.phoneNumber}</td>
                                                <td>{d.highScore} points</td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                            <div>
                                <Pagination
                                    hideDisabled
                                    activePage={activePage}
                                    itemsCountPerPage={itemsCountPerPage}
                                    totalItemsCount={reportDetails && reportDetails.length}
                                    pageRangeDisplayed={5}
                                    onChange={handlePageChange}
                                />
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default Admin;
