const isElementInViewport = (el: any) => {
    const rect = el.getBoundingClientRect();

    return (
        (rect.top <= 0 && rect.bottom >= 0) ||
        (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.top <= (window.innerHeight || document.documentElement.clientHeight)) ||
        (rect.top >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
    );
};

const throttle = (fn, wait) => {
    let time = Date.now();

    return function () {
        if (time + wait - Date.now() < 0) {
            fn();
            time = Date.now();
        }
    };
};

const injectLink = (link, href = '') => {
    const typoElt = document.getElementById('typography.js');
    if (typoElt) {
        typoElt.insertAdjacentHTML('afterend', link);
    }
};

const getFontsStr = (typography) => {
    let fontsStr = '';
    if (typography.options.googleFonts) {
        const fonts = typography.options.googleFonts.map((font) => {
            let str = '';
            str += font.name.split(' ').join('+');
            str += ':';
            str += font.styles.join(',');

            return str;
        });

        fontsStr = fonts.join('|');
    }

    return fontsStr;
};

const getFontsLink = (fontsStr) => {
    const href = `//fonts.googleapis.com/css?family=${fontsStr}`;
    const link = `<link href="${href}" rel="stylesheet" type="text/css" />`;
    return { link, href };
};

const injectFonts = (typography) => {
    const fontsStr = getFontsStr(typography);
    if (fontsStr) {
        const { link, href } = getFontsLink(fontsStr);
        injectLink(link, href);
    }
};

export { isElementInViewport, throttle, injectFonts };
