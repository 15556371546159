import React from 'react';
import './input.css';

const Input = ({ id, type = 'text', hints = [], label, name, className = '', ...props }: any) => {
    return (
        <div className={className}>
            <label htmlFor={name}>{label}</label>
            <input {...props} type={type} id={id} name={name} />

            {hints && hints.length > 0 && (
                <div
                    style={{
                        position: 'absolute',
                        top: '54px',
                        fontSize: '8px',
                    }}
                >
                    {hints.map(({ hint, position, className }, index) => (
                        <small
                            key={index}
                            className={`text-muted ${className}`}
                            style={{
                                display: 'block',
                                fontSize: '8px',
                            }}
                        >
                            {hint}
                        </small>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Input;
